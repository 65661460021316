.nav-link-custom {
  position: relative;
  transition: all 0.3s ease;
}

.nav-link-custom::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #cc3300;
  transition: all 0.3s ease;
}

.nav-link-custom:hover::after {
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.image-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%; /* Para tornar a imagem redonda */
}

.rounded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-container {
  margin-top: 10px;
  text-align: center;
  border: none;
}

.carousel-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-container {
  margin-bottom: 10px; /* Ajuste o espaçamento conforme necessário */
}

.custom-toggle {
  border: none !important; /* Remove a borda do botão */
  outline: none !important; /* Remove a borda de foco */
  background-color: transparent;
}

.custom-toggle .icon {
  font-size: 35px; /* Tamanho do ícone */
  transition: transform 0.3s ease; /* Transição suave */
  border: none !important; /* Remove a borda do botão */
  outline: none !important; /* Remove a borda de foco */
}

.custom-toggle .icon:hover {
  transform: scale(1.1); /* Aumenta um pouco o tamanho do ícone ao passar o mouse */
  border: none;
}

.navbar-toggler-icon {
  font-size: 24px; /* Tamanho do ícone padrão do Bootstrap */
  border: none;
}

.custom-toggle button {
  border: none !important; /* Remove a borda do botão */
  outline: none !important; /* Remove a borda de foco */
}

.custom-toggle button:focus {
  outline: none !important; /* Remove a borda de foco */
}

.dotted-line {
  border-top: 1px dashed #000; /* Define a borda superior pontilhada com 1 pixel de espessura e cor preta */
  width: 100%; /* Define a largura da linha igual a 100% */
  margin-top: 30px; /* Adiciona um espaço superior de 10 pixels (opcional) */
}

@font-face {
  font-family: 'Rage';
  src: url('./fonts/Rage.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}